import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';

import Project from 'components/Home/Work/Project';

const Projects = styled(Container)`
  margin-top: 50px;
  h1 {
    ${props => props.theme.main.fonts.displayLarge}
  }
`;

const ProjectsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  ${breakpoint('md')`
    margin-left: 0;
    margin-right: 0;
  `}
  &>a {
    flex: 0 calc(50% - 5px);
    width: calc(50% - 5px);
    margin-bottom: 34px;
    text-align: center;
    p {
      margin-bottom: 0;
    }
    ${breakpoint('md')`
      margin-bottom: 50px;
      flex: 0 calc(50% - 20px);
      width: calc(50% - 20px);
    `}
  }
`;

export default ({data}) => {
  return (
    <Projects>
      <h1>What we've done</h1>
      <ProjectsContainer>
        {data.allContentfulProject.nodes.map((project, i) => <Project project={project} key={i} />)}
      </ProjectsContainer>
    </Projects>);
}